<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "FormTemplates.NewFormTemplate",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-formtemplate-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success mt-2 mb-0">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="row row-cols-1">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "FormTemplates.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="model.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="OutputType" class="form-label required">{{
          $t(
            "FormTemplates.OutputType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          id="OutputType"
          type="SelectWithLocalData"
          :selectedData="selectedOutputType"
          :allowEmpty="false"
          :data="outputTypeData"
          @onChange="onChangeForOutputType"
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Description" class="form-label">{{
          $t(
            "FormTemplates.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          rows="5"
          v-model="model.description"
          :spellcheck="this.$isTextSpellCheck"
        ></textarea>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="ReportType" class="form-label required">{{
          $t(
            "FormTemplates.ReportType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          id="ReportType"
          type="SelectWithLocalData"
          :selectedData="selectedReportType"
          :allowEmpty="false"
          :data="reportTypeData"
          @onChange="onChangeForReportType"
        />
      </div>
      <div v-if="whichReport == 3" class="col col-md-6">
        <label for="DashboardReport" class="form-label required">{{
          $t(
            "FormTemplates.DashboardReports",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeDashboardReports"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Brs-DashboardReports?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div v-if="whichReport == 2" class="col col-md-6">
        <label for="XtraReports" class="form-label required">{{
          $t(
            "FormTemplates.XtraReports",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeXtraReports"
          :allowEmpty="false"
          :isParameters="true"
          :isDetailIconShow="true"
          :routeLink="String.format('#/CustomObject/Detail/')"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Brs-XtraReports?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
      <div v-if="whichReport != 1" class="col-md-6 mt-2">
        <label for="firstParameter" class="form-label required"
          >{{
            $t(
              "FormTemplates.FirstParameter",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeFirstParameter"
          :allowEmpty="false"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
              this.model.customObjectFormulaName
            )
          "
        />
      </div>
      <div v-if="whichReport != 1" class="col-md-6 mt-2">
        <label for="secondParameter" class="form-label"
          >{{
            $t(
              "FormTemplates.SecondParameter",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeSecondParameter"
          :allowEmpty="true"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFieldsWithFormula?customObjectFormulaName={0}',
              this.model.customObjectFormulaName
            )
          "
        />
      </div>
    </div>
    <div v-if="whichReport == 1" class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="File" class="form-label required">{{
          $t("FormTemplates.File", {}, { locale: this.$store.state.activeLang })
        }}</label>
        <div>
          <div
            class="p-4 bg-gray-100 file-drag-and-drop-block"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              id="File"
              @change="onFileSelected"
              ref="fileInput"
              class="hidden"
              accept=".docx"
              required
            />

            <label for="File" class="block cursor-pointer w-100 fs-5">
              <div class="text-center fw-bold">
                {{
                  $t(
                    "FormTemplates.BrowseOrDragandDrop",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
            </label>
            <ul
              class="list-group mt-3 list-group-numbered"
              v-if="this.selectedFiles.length"
            >
              <li
                class="list-group-item"
                v-for="file in selectedFiles"
                :key="file"
              >
                {{ file.name }}
                <span class="badge badge-primary"> {{ file.size }} kb</span
                ><button
                  class="btn btn-danger btn-sm btn-no-custom-style float-end"
                  type="button"
                  @click="remove(selectedFiles.indexOf(file))"
                >
                  <i class="bi bi-trash cursor-pointer"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="whichReport == 1" class="row row-cols-1 mt-3">
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsGetFromFilenameFields" class="form-label">{{
            $t(
              "FormTemplates.IsGetFromFilenameFields",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsGetFromFilenameFields"
            v-model="model.isGetFromFilenameFields"
            @click="
              isCheckForIsGetFromFilenameFields(model.isGetFromFilenameFields)
            "
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsSetEmptyIfValueNotFound" class="form-label">{{
            $t(
              "FormTemplates.IsSetEmptyIfValueNotFound",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsSetEmptyIfValueNotFound"
            v-model="model.isSetEmptyIfValueNotFound"
          />
        </div>
      </div>
      <div v-if="this.isFileNameFieldNames" class="col-md-12">
        <label for="FileNameFieldNames" class="form-label">{{
          $t(
            "FormTemplates.FileNameFieldNames",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onMultipleSelectChange="onChangeFileNameFieldNames"
          :allowEmpty="true"
          :isParameters="true"
          :isMultiple="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFields?coId={0}',
              this.$route.params.customObjectId
            )
          "
        />
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col-md-6">
        <div class="form-check">
          <label for="IsActive" class="form-label">{{
            $t(
              "FormTemplates.IsActive",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="model.isActive"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-check">
          <label
            for="IsPermissionApply"
            class="form-label"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="
              this.$t(
                'FormTemplates.ValidationTooltip',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            >{{
              $t(
                "FormTemplates.IsValidationApply",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label
          >
          <input
            class="form-check-input"
            type="checkbox"
            id="IsPermissionApply"
            v-model="model.isPermissionApply"
            @click="isCheckIsPermissionApply(model.isPermissionApply)"
          />
        </div>
      </div>
      <div v-if="whichReport != 1" class="col-md-6">
        <div class="form-check">
          <label for="IsPreviewEnabled" class="form-label">{{
            $t(
              "FormTemplates.IsPreviewEnabled",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</label>
          <input
            class="form-check-input"
            type="checkbox"
            id="IsPreviewEnabled"
            v-model="model.isPreviewEnabled"
          />
        </div>
      </div>
      <div v-if="this.isFormula" class="col col-md-6">
        <label for="Validation" class="form-label required">{{
          $t(
            "FormTemplates.Validation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          :allowEmpty="false"
          :resetSelect="selectsReset"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryComponentValidations?customObjectId={0}',
              this.$route.params.customObjectId
            )
          "
          @onChange="onChangeValidation"
        />
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "CustomObjectNewFormTemplate",
  components: {},
  data() {
    return {
      selectedFiles: [],
      notUploadedFiles: [],
      errors: [],
      buttonDisabled: false,
      isFileNameFieldNames: false,
      isFormula: false,
      whichReport: 1,
      model: {
        description: "",
        name: "",
        customObjectPublicId: this.$route.params.customObjectId,
        customObjectFormulaName:
          (this.$store.state.customObjectDto &&
            this.$store.state.customObjectDto.formulaName) ||
          null,
        outputType: 1,
        outputTypeId: 1,
        fileName: "",
        filePath: "docx",
        file: null,
        isGetFromFilenameFields: false,
        isSetEmptyIfValueNotFound: false,
        isActive: true,
        isPermissionApply: false,
        formula: "",
        reportType: 1,
        reportTypeId: 1,
        dashboardPublicId: "",
        dashboardName: "",
        xtraReportPublicId: "",
        xtraReportName: "",
        isPreviewEnabled: false,
        firstParameterPublicId: "",
        firstParameterFormulaName: "",
        firstParameterName: "",
        secondParameterPublicId: "",
        secondParameterFormulaName: "",
        secondParameterName: "",
        validationName: "",
        validationPublicId: "",
        fileNameFieldPublicIds: "",
        fileNameFieldNames: "",
      },
      outputTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Word",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportExportTypes.Pdf",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedOutputType: [
        {
          key: 1,
          value: this.$t(
            "ReportExportTypes.Word",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      reportTypeData: [
        {
          key: 1,
          value: this.$t(
            "ReportTypes.WordTemplate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "ReportTypes.XtraReport",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "ReportTypes.Dashboard",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedReportType: [
        {
          key: 1,
          value: this.$t(
            "ReportTypes.WordTemplate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      isSuccess: false,
    };
  },
  methods: {
    onChangeForOutputType: function (selected) {
      this.model.outputType = selected.key;
    },
    onChangeForReportType: function (selected) {
      this.model.reportTypeId = selected.key;
      this.model.reportType = selected.key;
      this.whichReport = 1;
      if (selected.key == 3) {
        this.whichReport = 3;
        this.model.xtraReportPublicId = "";
        this.model.xtraReportName = "";
        this.model.fileNameFieldPublicIds = "";
        this.model.isGetFromFilenameFields = false;
        this.model.isSetEmptyIfValueNotFound = false;
        this.model.fileName = "";
        this.model.file = null;
        this.selectedFiles = [];
        this.model.filePath = "";
      } else if (selected.key == 2) {
        this.whichReport = 2;
        this.model.dashboardPublicId = "";
        this.model.dashboardName = "";
        this.model.fileNameFieldPublicIds = "";
        this.model.isGetFromFilenameFields = false;
        this.model.isSetEmptyIfValueNotFound = false;
        this.model.fileName = "";
        this.model.file = null;
        this.selectedFiles = [];
        this.model.filePath = "";
      } else if (selected.key == 1) {
        this.model.xtraReportPublicId = "";
        this.model.xtraReportName = "";
        this.model.dashboardPublicId = "";
        this.model.dashboardName = "";
        this.model.firstParameterName = "";
        this.model.firstParameterFormulaName = "";
        this.model.firstParameterPublicId = "";
        this.model.secondParameterName = "";
        this.model.secondParameterFormulaName = "";
        this.model.secondParameterPublicId = "";
      }
    },
    onChangeDashboardReports: function (selected) {
      this.model.dashboardPublicId = selected.key;
      this.model.dashboardName = selected.value;
    },
    onChangeXtraReports: function (selected) {
      this.model.xtraReportPublicId = selected.key;
      this.model.xtraReportName = selected.value;
    },
    onChangeFirstParameter: function (selected) {
      this.model.firstParameterPublicId = selected.publicId;
      this.model.firstParameterFormulaName = selected.key;
      this.model.firstParameterName = selected.value;
    },
    onChangeSecondParameter: function (selected) {
      this.model.secondParameterPublicId = selected.publicId;
      this.model.secondParameterFormulaName = selected.key;
      this.model.secondParameterName = selected.value;
    },
    onChangeFileNameFieldNames(ids) {
      this.model.fileNameFieldPublicIds = ids;
    },
    onChangeValidation: function (selected) {
      this.model.validationPublicId = selected.key;
      this.model.validationName = selected.value;
    },
    isCheckForIsGetFromFilenameFields(selected) {
      if (selected == false) {
        this.isFileNameFieldNames = true;
      } else {
        this.isFileNameFieldNames = false;
        this.model.fileNameFieldPublicIds = "";
        this.model.fileNameFieldNames = "";
      }
    },
    isCheckIsPermissionApply(selected) {
      if (selected == false) {
        this.isFormula = true;
        this.model.validationName = "";
        this.model.validationPublicId = "";
      } else {
        this.isFormula = false;
        this.model.formula = "";
      }
    },
    onFileSelected() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      if (!this.extensionControl(this.selectedFiles[0].name)) {
        return;
      }
      this.errors = [];
      this.model.file = this.selectedFiles[0];
      this.model.fileName = this.selectedFiles[0].name;
      this.model.filePath = "." + this.selectedFiles[0].name.split(".").pop();
    },
    dragAndDropOnChange() {
      this.selectedFiles = [...this.$refs.fileInput.files];
      this.model.file = this.selectedFiles[0];
      this.model.fileName = this.selectedFiles[0].name;
      this.model.filePath = "." + this.selectedFiles[0].name.split(".").pop();
    },
    remove(i) {
      this.selectedFiles.splice(i, 1);
      this.model.file = null;
      this.model.fileName = "";
      this.model.filePath = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileInput.files = event.dataTransfer.files;
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
      if (!this.extensionControl(event.dataTransfer.files[0].name)) {
        return;
      }
      this.dragAndDropOnChange(); // Trigger the onChange event manually
    },
    extensionControl(name) {
      if (!name.includes(".docx")) {
        createToast(
          this.$t(
            "FieldErrors.UploadFileNotSupportedForWord",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        this.selectedFiles = [];
        this.$refs.fileInput.files = "";
        return false;
      }
      return true;
    },
    onClear() {
      this.model = {
        isActive: true,
        outputType: this.model.outputType,
        customObjectPublicId: this.model.customObjectPublicId,
        customObjectFormulaName: this.model.customObjectFormulaName,
      };

      this.clearedForm = !this.clearedForm;
      $(".form-formtemplate-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-formtemplate-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var formData = new FormData();
      formData.append("CustomObjectPublicId", this.model.customObjectPublicId);
      // formData.append(
      //   "RecordPublicId",
      //   document.getElementById("RecordPublicId").value
      // );
      //formData.append("FieldPublicId", self.field.publicId);
      formData.append("FilePath", this.model.filePath);
      formData.append("FileName", this.model.fileName);
      formData.append("File", this.model.file);
      formData.append("Name", this.model.name);
      formData.append("Description", this.model.description);
      formData.append("IsActive", this.model.isActive);
      formData.append("OutputType", this.model.outputType);
      formData.append(
        "IsGetFromFilenameFields",
        this.model.isGetFromFilenameFields
      );
      formData.append(
        "IsSetEmptyIfValueNotFound",
        this.model.isSetEmptyIfValueNotFound
      );
      formData.append("IsPermissionApply", this.model.isPermissionApply);
      formData.append("DisplayOrder", 1);
      formData.append(
        "FileNameFieldPublicIds",
        this.model.fileNameFieldPublicIds
      );
      formData.append("FileNameFieldNames", this.model.fileNameFieldNames);
      formData.append("Formula", this.model.formula);
      formData.append("ValidationPublicId", this.model.validationPublicId);
      formData.append("ValidationName", this.model.validationName);
      formData.append("ReportType", this.model.reportType);
      formData.append("ReportTypeId", this.model.reportTypeId);
      formData.append("DashboardpublicId", this.model.dashboardPublicId);
      formData.append("DashboardName", this.model.dashboardName);
      formData.append("XtraReportPublicId", this.model.xtraReportPublicId);
      formData.append("XtraReportName", this.model.xtraReportName);
      formData.append("IsPreviewEnabled", this.model.isPreviewEnabled);
      formData.append("FirstParameterName", this.model.firstParameterName);
      formData.append(
        "FirstParameterPublicId",
        this.model.firstParameterPublicId
      );
      formData.append(
        "FirstParameterFormulaName",
        this.model.firstParameterFormulaName
      );
      formData.append("SecondParameterName", this.model.secondParameterName);
      formData.append(
        "SecondParameterPublicId",
        this.model.secondParameterPublicId
      );
      formData.append(
        "SecondParameterFormulaName",
        this.model.secondParameterFormulaName
      );
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-FormTemplateNew", formData)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push(
                "/CustomObject/FormTemplates/" +
                  this.$route.params.customObjectId
              );
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);

            if (String.isNullOrWhiteSpace(this.model.fileName)) {
              this.errors.push("File Not Found");
            } else {
              this.errors.push(response.msg);
            }
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  watch: {
    "$store.state.customObjectDto"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.model.customObjectPublicId =
          this.$store.state.customObjectDto.publicId;
        this.model.customObjectFormulaName =
          this.$store.state.customObjectDto.formulaName;
      }
    },
  },
};
</script>
